import React from "react";
import CovidImg from "../assets/images/blog-image/covid.jpg"
import CloudImg from "../assets/images/blog-image/cloud-computing.jpg"
import ChartDownImg from "../assets/images/blog-image/chart-down.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const BlogList = () => {
  
    let history = useHistory();

    let navTo = (url) => {
        history.push(url);
    }

    return (
        <section className="illustration-section-01">
            <div className="container section-inner text-center">
                <h1>Site Change Blog</h1>
                <label>Content to fulfill all your website change detection needs</label>
                <div className="blog-items">
                    <a className="blog-item" href="/Blog/Tracking-Covid-Vaccine">
                        <img className="blog-image" src={CovidImg} alt="Covid Vaccine" />
                        <div className="blog-title">
                            <div className="blog-type">Website Change Detection Tips</div>
                            How to Get an Alert When A COVID-19 Vaccine becomes available in your area.
                        </div>
                    </a>
                    {/* 
                    <div className="blog-item">
                        <img className="blog-image" src={ChartDownImg} alt="Chart Downwards" />
                        <div className="blog-title">
                            <div className="blog-type">Website Change Detection Tips</div>
                            How to Setup Automatic Alerts For Price or Stock Changes on Any Website.
                        </div>
                    </div>
                    */}
                    <a className="blog-item" href="/Blog/Where-To-Store-Images">
                        <img className="blog-image" src={CloudImg} alt="Cloud Technology" />
                        <div className="blog-title">
                            <div className="blog-type">Software Development</div>
                            Database vs Cloud Storage vs File Systems for Storing Images
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default BlogList;
