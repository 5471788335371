class PaymentApi {
    
    constructor() {
        if(process.env.NODE_ENV === "development"){
            this.baseUrl = "http://127.0.0.1:5000/api/";

        }else{
            this.baseUrl = "https://api.site-change.com/api/";
        }
    }

    AddPaymentMethod(firstName, lastName, paymentToken, subscriptionName) {
        return fetch(this.baseUrl + "Payment/AddPaymentMethod", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify({
                fistName: firstName,
                lastName: lastName,
                paymentToken: paymentToken,
                subscriptionId: subscriptionName
            })
        }).then(x => x.json());
    }

    CancelSubscription() {
        return fetch(this.baseUrl + "Payment/CancelSubscription", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(x => x.json());
    }

    StartSubscription(PayPal) {
        return fetch(this.baseUrl + "Payment/StartSubscription", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify(PayPal)
        }).then(x => x.json());
    }
}

export default PaymentApi;