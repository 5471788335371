class ContactApi {
    
    constructor() {
        if(process.env.NODE_ENV === "development"){
            this.baseUrl = "http://127.0.0.1:5000/api/";

        }else{
            this.baseUrl = "https://api.site-change.com/api/";
        }
    }

    SendContactRequest(name, email, message) {
        return fetch(this.baseUrl + "Contact/SendContactRequest", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            })
        }).then(x => x.json());
    }
}

export default ContactApi;