/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AuthApi from '../api/AuthApi';
import Logo from '../components/layout/partials/Logo';
import LoadingSpinner from "../components/elements/LoadingSpinner"
import { Link } from 'react-router-dom';

const AppSumo = () => {

  let authApi = new AuthApi();

  let { urlKey } = useParams();

  let [email, setEmail] = useState("");
  let [website, setWebsite] = useState("");
  let [error, setError] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [pageLoad, setPageLoad] = useState(false);
  let [key, setKey] = useState(urlKey);

  let updateEmail = (e) => setEmail(e.target.value);
  let updateWebsite = (e) => setWebsite(e.target.value);
  let updateKey = (e) => setKey(e.target.value);
  
  const history = useHistory();

  useEffect(() => {
    authApi.IsLoggedIn().then(x => {
      if(x.ok){
        history.push("/Dashboard");
      }else{
        setPageLoad(true);
      }
    });
  }, [])

  let userCreate = (e) => {
    e.preventDefault();

    if(!email || !website || !key){
      setError("Please enter a username, website, and key");
      return;
    }
    else
    {
      setError("");
    }

    authApi.RegisterLifeTime(website, email, key).then(result => {
      console.log(result);
      if(result.success){
        history.push("/AlmostThere");
      }else{
        setError(result.message);
      }
      setIsLoading(false);
    }).catch(x => {
      setError("An unknown error has occurred.");
      setIsLoading(false);
    });
    
  }

  let showValidationError = () => {
    if(error){
      return <label className="validation-label">{error}</label>
    }else{
      return <></>
    }
  }

  if(pageLoad){
    return (
      <div>
          <div className="container">
            <div className="site-header-inner">
              <Logo></Logo>
            </div>
            <h1 className="login-header animate__animated animate__fadeInDown animate__faster">AppSumo Lifetime Deal</h1>
            <div className="login-wrapper animate__animated animate__fadeInUp animate__faster">
              <form className="login-box" onSubmit={(e) => userCreate(e)}>
                <label className="login-label">Email</label>
                <input className="input login-input" value={email} onChange={updateEmail}></input>
                <label className="login-label">Website To Track</label>
                <input className="input login-input" value={website} onChange={updateWebsite}></input>
                <label className="login-label">AppSumo Code</label>
                <input className="input login-input" value={key} onChange={updateKey}></input>
                {showValidationError()}
                <button type="submit" className="button button-primary login-button">
                  <LoadingSpinner isLoading={isLoading} DisplayText="Redeem"></LoadingSpinner>
                </button>
                <span style={
                  {
                    width: '100%', 
                    textAlign: 'center', 
                    fontSize: 'medium'
                  }}>
                  By using this website you agree to the <Link to="/PrivacyPolicy">privacy policy</Link> and the <Link to="/TermsConditions">terms and conditions</Link>
                </span>
              </form>
            </div>
          </div>
      </div>
    );
  }else{
    return (
      <div style={{textAlign: "center", marginTop: "200px"}}>
        <LoadingSpinner isLoading={true}></LoadingSpinner>
      </div>
    );
  }
  
}

export default AppSumo;