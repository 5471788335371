import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AuthApi from "../api/AuthApi";
import AffiliateApi from "../api/AffliliateApi";
import LoadingSpinner from "../components/elements/LoadingSpinner";

const Affiliate = () => {

    let authApi = new AuthApi();
    let affiliateApi = new AffiliateApi();

    let [error, setError] = useState("");
    let [message, setMessage] = useState("");

    let [loading, setLoading] = useState(true);
    let [settingLink, setSettingLink] = useState(false);

    let [affiliateCode, setAffiliateCode] = useState("");
    let [registeredUsers, setRegisteredUsers] = useState(5);
    let [payingUsers, setPayingUsers] = useState(5);

    let history = useHistory();

    useEffect(() => {
        authApi.IsLoggedIn().then(x => {
          if(!x.ok){
            history.push("/");
          }else{
              affiliateApi.GetDetails().then(y => {
                  console.log(y);
                    setAffiliateCode(y.code);
                    setRegisteredUsers(y.created);
                    setPayingUsers(y.paying);
                    setLoading(false);
              });
          }
        });
      }, [])

      const setLink = () => {
        setSettingLink(true);
        setError("");
        setMessage("");
        affiliateApi.CreateLink(affiliateCode).then(x => {
            if(x => x.success){
                setMessage("New link set!");
            }else{
                setError(x.message);
            }

            setSettingLink(false);
        }).catch(err => {
            setError("An unknown error has occured");
            setSettingLink(false);
        })
      }

      if(loading){
          return (
            <div style={{ textAlign: "center", marginTop: "200px" }}>
                <LoadingSpinner isLoading={true}></LoadingSpinner>
            </div>
          );
      }

      return (
        <div>
          <div className="container">
            <div className="site-header-inner"></div>
          </div>
          <div className="container-small dashboard-container">
          <div className="dashboard-pane">
            <h3 className="animate__animated animate__fadeInDown animate__faster">Affiliate Code</h3>
            <div className="dashboard-area dashboard-with-bottom">
                <div className="dashboard-form animate__animated animate__fadeInUp animate__faster">
                    <div className="form-item form-item-large">
                        <label className="label">Affiliate Code</label>
                        <input className="input" value={affiliateCode} onChange={(e) => setAffiliateCode(e.target.value)}></input>
                    </div>
                    <div className="form-item form-item-large">
                        <label className="label">Your Link</label>
                        <input className="input" disabled value={affiliateCode === "" ? "" : "https://site-change.com/?ref=" + affiliateCode}></input>
                    </div>
                    <div className="form-item">
                        <label className="label">Users Registered</label>
                        <label className="label">{registeredUsers}&nbsp;</label>
                    </div>
                    <div className="form-item">
                        <label className="label">Users Paying</label>
                        <label className="label">{payingUsers}&nbsp;</label>
                    </div>
                </div>
                <div className="footer-button-group animate__animated animate__fadeInUp animate__faster">
                    <label className="validation-label">{error}</label>
                    <label className="message-label">{message}</label>
                    <button className="button button-primary" onClick={setLink}>
                        <LoadingSpinner DisplayText="Set Affiliate Code" isLoading={settingLink}></LoadingSpinner>
                    </button>
                </div>
            </div>
            </div>
          </div>
        </div>
      );
}

export default Affiliate;