/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import Logo from '../components/layout/partials/Logo';
import { parse } from 'query-string';
import AuthApi from '../api/AuthApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LoadingSpinner from '../components/elements/LoadingSpinner';
import { Link } from 'react-router-dom';

const ResetPassword = () => {

  let authApi = new AuthApi();

  let [email, setEmail] = useState(parse(location.search).email);
  let password = parse(location.search).token;
  let [newPassword, setNewPassword] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [error, setError] = useState("");

  let updateEmail = (e) => setEmail(e.target.value);
  let updatePassword = (e) => setNewPassword(e.target.value);

  const history = useHistory();

  let userLogin = (e) => {
    e.preventDefault();
    
    if(!email || !newPassword){
      setError("Please enter an email and password.")
      return;
    }

    setError("");
    setIsLoading(true);

    authApi.ChangePassword(email, password, newPassword).then(result => {
      if(result.success){
        localStorage.setItem("token", result.results.token);
        history.push("/Dashboard");
      }
      else
      {
        setError(result.message);
      }
      
      setIsLoading(false);
    }).catch(x => {
      setError("An unknown error has occurred.")
      setIsLoading(false);
    });
  }

  let showValidationError = () => {
    if(error){
      return <label className="validation-label">{error}</label>
    }else{
      return <></>
    }
  }

  return (
    <div>
        <div className="container">
          <div className="site-header-inner">
            <Logo></Logo>
          </div>
          <h1 className="login-header reveal-from-bottom">Set Password</h1>
          <div className="login-wrapper reveal-from-bottom">
            <form className="login-box" onSubmit={(e) => userLogin(e)}>
              <label className="login-label">Email</label>
              <input className="input login-input" value={email} onChange={updateEmail}></input>
              <label className="login-label">Password</label>
              <input type="password" className="input login-input" value={newPassword} onChange={updatePassword}></input>
              {showValidationError()}
              <button type="submit" className="button button-primary login-button">
                <LoadingSpinner DisplayText="Login" isLoading={isLoading}></LoadingSpinner>
              </button>
              <span style={
                  {
                    width: '100%', 
                    textAlign: 'center', 
                    fontSize: 'medium'
                  }}>
                  By using this website you agree to the <Link to="/PrivacyPolicy">privacy policy</Link> and the <Link to="/TermsConditions">terms and conditions</Link>
                </span>
            </form>
          </div>
        </div>
    </div>
  );
}

export default ResetPassword;