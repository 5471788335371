import React from 'react'
import CloudImg from '../../assets/images/blog-image/cloud-computing.jpg'

const ImageStorage = () => {

    return (
        <section className="illustration-section-01">
            <div className="container section-inner text-center">
                <h2>Site Change Blog</h2>
                <div className="blog-post">
                    <img className="blog-header-image" src={CloudImg} alt="Covid Vaccine"/>
                    <div className="blog-area">
                        <div className="blog-header">
                            <h1>Database vs Cloud Storage vs File Systems for Storing Images</h1>
                        </div>
                        <p className="main">
                            With the web becoming such a multimedia focused giant and image storage seeming to be a must have on just about every website the question about where to store the images seems to be asked more and more. Through my experience and research, it really comes down to what exactly your use case is for storing the images. I am going to go through the pros and cons of saving your images in a database, cloud storage and a local file system. 
                        </p>
                        <h3>Database</h3>
                        <p>
                            When working with a database it almost makes sense in your flow to just save the images to your database in either a binary format or in a base64 format. Starting off that is what <a href="https://site-change.com">SiteChange</a> was doing. However, once the image count starts to build up querying on your data can get slower and degrade your database performance. After reading some information online I have found that it is best to only store images under 5mb in your database. If you do plan to go this route you should also have a separate table just dedicated to storing the images. If you just have 1 or 2 images that a user is going to upload, and you know they are going to be on the smaller side it may make sense to go this route to save time and have less connected things to worry about.
                        </p>
                        <h3>File System</h3>
                        <p>
                            If you do not want to slow your database down this may be a great next option for you. If you are saving and serving the static images right from the same disk that your website is on this is going to significantly increase performance overall by removing the database call and the additional network layer the image data needs to transfer through. If you just have some static content without needing to update it frequently or have users upload images this may be the perfect solution for you. Again, however the file system just like the database solution comes with its downsides. One of the main issues being scalability. Often, we have multiple servers serving our content so keeping all the disks in sync or keeping track of what image is stored on what server can really be a hassle.
                        </p>
                        <h3>Cloud Storage</h3>
                        <p>
                        The world just seems to be navigating more and more to cloud based solutions for the immense reliability and scalability it provides but with the steep downside of cost. Cloud storage is going to feel a lot like storing directly to a file system just abstracted behind a different API. This is going to make sure your database stays fast, and you do not have to worry about frequent backups or scalability of your file storage solution. The cloud is also getting insanely cheap and is currently what <a href="https://site-change.com">SiteChange</a> is using to power its website change detection service. I would recommend a cloud approach if you are at all worried about scalability or performance and with the <a href="https://aws.amazon.com/free/">AWS free tier</a> being so generous, from a hobby perspective you do not have to worry about paying more then a few pennies per month.
                        </p>
                        <div className="text-center">
                            <a href="https://site-change.com" className="white text-center button button-primary">Get Started Tracking</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageStorage;