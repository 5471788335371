import React from 'react';
import Logo from '../components/layout/partials/Logo';

const Message = () => {

  return (
    <div>
        <div className="container">
          <div className="site-header-inner">
            <Logo></Logo>
          </div>
          <h1 className="login-header reveal-from-bottom animate__animated animate__fadeInDown animate__faster">Almost There</h1>
          <div className="login-wrapper reveal-from-bottom animate__animated animate__fadeInUp animate__faster">
            <div className="login-box">
              <label className="login-label">Please check your inbox for a verification email to start tracking.</label>
            </div>
          </div>
        </div>
    </div>             
  );
}

export default Message;