/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import AuthApi from "../api/AuthApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingSpinner from "../components/elements/LoadingSpinner";
import LayoutDash from "../layouts/LayoutDash";
import PriceCard from "../components/pricing/PriceCard";
import PaymentForm from "../components/pricing/PaymentForm";
import AccountApi from "../api/AccountApi";
import CancelForm from "../components/pricing/CancelForm";

const Upgrade = () => {
  var [loggedIn, setLoggedIn] = useState(false);
  var [isLoaded, setIsLoaded] = useState(false);
  var [currentPlan, setCurrentPlan] = useState("Starter");
  var [planEndDate, setPlanEndDate] = useState(null);

  const authApi = new AuthApi();
  const accountApi = new AccountApi();
  
  var [displayMode, setDisplayMode] = useState("current");

  const history = useHistory();

  let [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    authApi.IsLoggedIn().then((x) => {
      if (x.ok) {
        setLoggedIn(true);
      } else {
        history.push("/Login");
      }
    });
  }, [authApi, history]);

  useEffect(() => {
    accountApi.GetAccountInfo().then(x => {
      setIsLoaded(true);
      if(x.isBilling){
        setCurrentPlan(x.plan === null ? "Starter" : x.plan);
      }else{
        setCurrentPlan("Starter");
      }
      
      setPlanEndDate(new Date(x.nextBill));
    });
  }, [])

  const ClearSelection = () => {
    setSelectedItem(null);
  }

  let GetPaymentForm = () => {
    if(selectedItem){
      if(selectedItem.Price == "Free"){
        return (
          <CancelForm ClearPlan={ClearSelection} LastSubDay={planEndDate}></CancelForm>
        )
      }
      else
      {
        return (
          <PaymentForm ClearPlan={ClearSelection} PlanName={selectedItem.PlanName} Price={selectedItem.Price}></PaymentForm>
        )
      } 
    }
  }

  if (loggedIn && isLoaded) {
    return (
      <LayoutDash setDisplayMode={setDisplayMode} displayMode={displayMode}>
        <div>
          <div className="container">
            <div className="site-header-inner"></div>
          </div>
          <div className="container dashboard-container">
            <PriceCard
            Hidden={selectedItem && selectedItem.PlanName !== "Starter"}
            SelectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            PlanName="Starter"
            Price = "Free"
            Length = "Forever"
            PageAmount = {2}
            PageFrequency = "Pages Per Day"
            Features = {[
                "No credit card required",
                "Check up to 2 pages per day"
            ]}
            CheckAmount={64}
            CardNumber="1"
            IsCurrent={currentPlan === "Starter"}></PriceCard>

            <PriceCard
            Hidden={selectedItem && selectedItem.PlanName !== "Basic"}
            SelectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            PlanName="Basic"
            Price = "1"
            Length = "Per Month"
            PageAmount = {5}
            PageFrequency = "Pages Per Day"
            Features = {[
                "Basic support",
                "Check up to 5 pages per day"
            ]}
            CheckAmount={160}
            CardNumber="2"
            IsCurrent={currentPlan === "Basic"}></PriceCard>

            <PriceCard
            Hidden={selectedItem && selectedItem.PlanName !== "Premium"}
            SelectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            PlanName="Premium"
            Price = "5"
            Length = "Per Month"
            PageAmount = {25}
            PageFrequency = "Pages Per Day"
            Features = {[
                "Hourly checks",
                "Check up to 25 pages per day"
            ]}
            CheckAmount={800}
            CardNumber="3"
            IsCurrent={currentPlan === "Premium"}></PriceCard>

            <PriceCard
            Hidden={selectedItem && selectedItem.PlanName !== "Premium+"}
            SelectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            PlanName="Premium+"
            Price = "10"
            Length = "Per Month"
            PageAmount = {50}
            PageFrequency = "Pages Per Day"
            Features = {[
                "Half-hour checks",
                "Check up to 50 pages per day"
            ]}
            CheckAmount={1600}
            CardNumber="4"
            IsCurrent={currentPlan === "Premium+"}></PriceCard>

            <PriceCard
            Hidden={selectedItem && selectedItem.PlanName !== "Enterprise"}
            SelectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            PlanName="Enterprise"
            Price = "25"
            Length = "Per Month"
            PageAmount = {200}
            PageFrequency = "Pages Per Day"
            Features = {[
                "Priority support",
                "Check up to 200 pages per day"
            ]}
            CheckAmount={6400}
            CardNumber="5"
            IsCurrent={currentPlan === "Enterprise"}></PriceCard>

            <PriceCard
            Hidden={selectedItem && selectedItem.PlanName !== "Enterprise+"}
            SelectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            PlanName="Enterprise+"
            Price = "50"
            Length = "Per Month"
            PageAmount = {500}
            PageFrequency = "Pages Per Day"
            Features = {[
                "5 minute checks",
                "Check up to 500 pages per day"
            ]}
            CheckAmount={16000}
            CardNumber="6"
            IsCurrent={currentPlan === "Enterprise+"}></PriceCard>

            {GetPaymentForm()}

          </div>
        </div>
      </LayoutDash>
    );
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <LoadingSpinner isLoading={true}></LoadingSpinner>
      </div>
    );
  }
};

export default Upgrade;
