/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DashboardApi from "../../api/DashboardApi";
import WebsiteRow from "./WebsiteRow";

const CurrentSites = ({ changeState, changeSelected, currentState }) => {
  var [trackedSites, setTrackedSites] = useState([]);
  var dashboardApi = new DashboardApi();

  useEffect(() => { 
    if(currentState === "current"){
      setTrackedSites([])
      dashboardApi.GetTrackedSites().then((y) => setTrackedSites(y.results)).catch(x => {
        console.log("Tracked Site Error");
      });
    }
  }, [currentState]);

  let deleteWebsite = (website) => {
    return dashboardApi.DeleteWebsite(website).then(x => {
      setTrackedSites([])
      dashboardApi.GetTrackedSites().then((y) => setTrackedSites(y.results)).catch(x => {
        console.log("Tracked Site Error");
      });
    });
  }

  let statsClicked = (website) => {
    changeSelected(website);
    changeState("stats");
  }

  let editClicked = (website) => {
      changeSelected(website);
      changeState("edit");
  };

  return (
    <div className="dashboard-pane">
      <div className="dashboard-header animate__animated animate__fadeInDown animate__faster">
        <h3>Current Sites</h3>
        <button className="header button button-primary" onClick={() => changeState("create")}>Add New</button>
      </div>
      <div className="dashboard-area">
        {trackedSites.map((x) => {
          return <WebsiteRow key={x.id} website={x} editClicked={editClicked} deleteWebsite={deleteWebsite} statsClicked={statsClicked}></WebsiteRow>;
        })}
      </div>
    </div>
  );
};

export default CurrentSites;
