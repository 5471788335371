import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import LayoutNoNav from './layouts/LayoutNoNav';
import Dashboard from './views/Dashboard';
import Message from './views/Message';
import Account from './views/Account';
import Upgrade from './views/Upgrade';
import PaymentForm from './components/pricing/PaymentForm';
import ContactUs from './views/ContactUs';
import PrivacyPolicy from './views/PrivacyPolicy';
import TermsConditions from './views/TermsConditions';
import Affiliate from './views/Affiliate';
import BlogList from './views/BlogList';
import BlogRoutes from './BlogPosts/BlogRoutes';
import AppSumo from './views/AppSumo'

// Initialize Google Analytics
ReactGA.initialize("G-M8X6FYNDH7");

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/ContactUs" component={ContactUs} layout={LayoutDefault} />
          <AppRoute exact path="/PrivacyPolicy" component={PrivacyPolicy} layout={LayoutDefault} />
          <AppRoute exact path="/TermsConditions" component={TermsConditions} layout={LayoutDefault} />
          <AppRoute exact path="/Login" component={Login} layout={LayoutNoNav} />
          <AppRoute exact path="/AppSumo/:urlKey" component={AppSumo} layout={LayoutNoNav} />
          <AppRoute exact path="/Dashboard" component={Dashboard} layout={LayoutNoNav} />
          <AppRoute exact path="/Account" component={Account} layout={LayoutNoNav} />
          <AppRoute exact path="/AlmostThere" component={Message} layout={LayoutNoNav} />
          <AppRoute exact path="/ResetPassword" component={ResetPassword} layout={LayoutNoNav} />
          <AppRoute exact path="/Upgrade" component={Upgrade} layout={LayoutNoNav} />
          <AppRoute exact path="/Pay" component={PaymentForm} layout={LayoutNoNav} />
          <AppRoute exact path="/ForgotPassword" component={ForgotPassword} layout={LayoutNoNav} />
          <AppRoute exact path="/Affiliate" component={Affiliate} layout={LayoutDefault} />
          <AppRoute exact path="/Blog" component={BlogList} layout={LayoutDefault} />
          <BlogRoutes></BlogRoutes>
        </Switch>
      )} />
  );
}

export default App;