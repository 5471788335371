import React, { useEffect, useState } from "react";
import AuthApi from "../api/AuthApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CurrentSites from "../components/dashboard/CurrentSites";
import EditSite from "../components/dashboard/EditSite";
import CreateSite from "../components/dashboard/CreateSite";
import StatSite from "../components/dashboard/StatSite";
import LoadingSpinner from "../components/elements/LoadingSpinner";
import LayoutDash from "../layouts/LayoutDash";

const Dashboard = () => {
  var [loggedIn, setLoggedIn] = useState(false);

  const authApi = new AuthApi();

  var [displayMode, setDisplayMode] = useState("current");
  var [selectedItem, setSelectedItem] = useState({});

  const history = useHistory();

  useEffect(() => {
    authApi.IsLoggedIn().then((x) => {
      if (x.ok) {
        setLoggedIn(true);
      } else {
        history.push("/Login");
      }
    });
  }, [authApi, history]);

  let displaySection = () => {
    if (displayMode === "current") {
      return <CurrentSites changeSelected={setSelectedItem} changeState={setDisplayMode} currentState={displayMode}></CurrentSites>;
    } else if (displayMode === "edit") {
      return <EditSite setState={setDisplayMode} editingSite={selectedItem}></EditSite>;
    } else if (displayMode === "create") {
      return <CreateSite setState={setDisplayMode} editingSite={selectedItem}></CreateSite>;
    } else if (displayMode === "stats") {
      return <StatSite setState={setDisplayMode} editingSite={selectedItem}></StatSite>
    }
  };

  if (loggedIn) {
    return (
      <LayoutDash setDisplayMode={setDisplayMode} displayMode={displayMode}>
        <div>
          <div className="container">
            <div className="site-header-inner"></div>
          </div>
          <div className="container-small dashboard-container">{displaySection()}</div>
        </div>
      </LayoutDash>
    );
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <LoadingSpinner isLoading={true}></LoadingSpinner>
      </div>
    );
  }
};

export default Dashboard;
