import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AccountApi from "../../api/AccountApi";
import PaymentApi from "../../api/PaymentApi";
import LoadingSpinner from "../elements/LoadingSpinner";

const CancelForm = ({ClearPlan, LastSubDay}) => {

    let [loading, setLoading] = useState(false);
    let [error, setError] = useState("");
    
    const history = useHistory();

    const paymentApi = new PaymentApi();

    const CancelSubscription = () => {
        setLoading(true);
        paymentApi.CancelSubscription().then(x => {
            if(x.success)
            {
                history.push("/dashboard");
            }
            else
            {
                setError(x.Message);
            }
            setLoading(false);
        }).catch(err => {
            setError("An unknown error has occurred! Please contact support@site-change.com for help.");
            setLoading(false);
        })
    }

    return (
        <div className="payment-holder">
            <div className="form-item full-width medium-text bottom-padding">
                    <label>
                        Are you sure you want to cancel your subscription? 
                        If you do cancel your current subscription will last until {LastSubDay == null ? "" : LastSubDay.toLocaleDateString()}.
                    </label>
                </div>
                
                <button className="button button-primary full-width bottom-margin" onClick={CancelSubscription}>
                    <LoadingSpinner DisplayText="Cancel Subscription" isLoading={loading}></LoadingSpinner>
                </button>
                <button className="button button-primary full-width bottom-margin" onClick={ClearPlan}>Back</button>
            <div className="medium-text full-width error bottom-padding">{error}</div>
        </div>
    )

}

export default CancelForm;