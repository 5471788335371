class AffiliateApi {
    
    constructor() {
        if(process.env.NODE_ENV === "development"){
            this.baseUrl = "http://127.0.0.1:5000/api/";

        }else{
            this.baseUrl = "https://api.site-change.com/api/";
        }
    }

    GetDetails() {
        return fetch(this.baseUrl + "Affiliate/GetDetails", {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(x => x.json());
    }

    CreateLink(linkText) {
        return fetch(this.baseUrl + "Affiliate/CreateLink", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                linkText: linkText
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(x => x.json());
    }
}

export default AffiliateApi;