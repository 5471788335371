import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import PriceCard from "../pricing/PriceCard";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top',
    'container dashboard-container',
    'reveal-from-bottom'
  );

  const sectionHeader = {
    title: 'Workflow that just works',
    paragraph: 'Simple and easy to use software at your fingertips. Just enter in the site you want to be tracked, how often to get notified and just like that, you will receive notifications as frequent as you want.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

          <PriceCard
            PlanName="Starter"
            Price = "Free"
            Length = "Forever"
            PageAmount = {2}
            PageFrequency = "Pages Per Day"
            Features = {[
                "No credit card required",
                "Check up to 2 pages per day"
            ]}
            CheckAmount={64}
            CardNumber="1"
            ButtonText="Get Started"
            ButtonLink="#Signup"></PriceCard>

            <PriceCard
            PlanName="Basic"
            Price = "1"
            Length = "Per Month"
            PageAmount = {5}
            PageFrequency = "Pages Per Day"
            Features = {[
                "Basic support",
                "Check up to 5 pages per day"
            ]}
            CheckAmount={160}
            CardNumber="2"
            ButtonText="Get Started"
            ButtonLink="#Signup"></PriceCard>

            <PriceCard
            PlanName="Premium"
            Price = "5"
            Length = "Per Month"
            PageAmount = {25}
            PageFrequency = "Pages Per Day"
            Features = {[
                "Hourly checks",
                "Check up to 25 pages per day"
            ]}
            CheckAmount={800}
            CardNumber="3"
            ButtonText="Get Started"
            ButtonLink="#Signup"></PriceCard>

            <PriceCard
            PlanName="Premium+"
            Price = "10"
            Length = "Per Month"
            PageAmount = {50}
            PageFrequency = "Pages Per Day"
            Features = {[
                "Half-hour checks",
                "Check up to 50 pages per day"
            ]}
            CheckAmount={1600}
            CardNumber="4"
            ButtonText="Get Started"
            ButtonLink="#Signup"></PriceCard>

            <PriceCard
            PlanName="Enterprise"
            Price = "25"
            Length = "Per Month"
            PageAmount = {200}
            PageFrequency = "Pages Per Day"
            Features = {[
                "Priority support",
                "Check up to 200 pages per day"
            ]}
            CheckAmount={6400}
            CardNumber="5"
            ButtonText="Get Started"
            ButtonLink="#Signup"></PriceCard>

            <PriceCard
            PlanName="Enterprise+"
            Price = "50"
            Length = "Per Month"
            PageAmount = {500}
            PageFrequency = "Pages Per Day"
            Features = {[
                "5 minute checks",
                "Check up to 500 pages per day"
            ]}
            CheckAmount={16000}
            CardNumber="6"
            ButtonText="Get Started"
            ButtonLink="#Signup"></PriceCard>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;