import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  function getEnv() {
    if(process.env.NODE_ENV === "development"){
      return (<>(Dev)</>)
    }
    else if(process.env.NODE_ENV === "test")
    {
      return (<>(Test)</>)
    }
    else
    {
      return (<></>)
    }
  }

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0 logo-header">
        <Link to="/">
          <span className="header-image-container">
            <Image
              src={require('./../../../assets/images/MainLogo.svg')}
              alt="Logo"
              width={32}
              height={32}
              className="header-image" />
            <label>Site Change {getEnv()}</label>
          </span>
        </Link>
      </h1>
    </div>
  );
}

export default Logo;