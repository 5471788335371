import React from 'react';

const LoadingSpinner = ({isLoading, DisplayText}) => {

  if(isLoading){
    return (
      <div className="cssload-container">
        <div className="cssload-speeding-wheel"></div>
      </div>
    );
  }else{
    return (
    <>
      {DisplayText}
    </>
    );
  }
}

export default LoadingSpinner;