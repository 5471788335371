import React, { useEffect, useState } from "react";
import AuthApi from "../api/AuthApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingSpinner from "../components/elements/LoadingSpinner";
import LayoutDash from "../layouts/LayoutDash";
import AccountInfo from "../components/dashboard/AccountInfo";

const Account = () => {
  var [loggedIn, setLoggedIn] = useState(false);
  const authApi = new AuthApi();

  var [displayMode, setDisplayMode] = useState("current");

  const history = useHistory();

  useEffect(() => {
    authApi.IsLoggedIn().then((x) => {
      if (x.ok) {
        setLoggedIn(true);
      } else {
        history.push("/Login");
      }
    });
  }, [authApi, history]);

  if (loggedIn) {
    return (
      <LayoutDash setDisplayMode={setDisplayMode} displayMode={displayMode}>
        <div>
          <div className="container">
            <div className="site-header-inner"></div>
          </div>
          <div className="container-small dashboard-container">
            <AccountInfo></AccountInfo>
          </div>
        </div>
      </LayoutDash>
    );
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <LoadingSpinner isLoading={true}></LoadingSpinner>
      </div>
    );
  }
};

export default Account;
