import React, { useState } from "react";
import ContactApi from "../api/ContactApi";
import LoadingSpinner from "../components/elements/LoadingSpinner";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const contactApi = new ContactApi();

  const sendContactMessage = (event) => {
    event.preventDefault();

    if (name === "") {
      setError("Please enter a name.");
      return;
    }

    if (email === "") {
      setError("Please enter an email.");
      return;
    }

    if (message === "") {
      setError("Please enter a message.");
      return;
    }

    setSuccess("");
    setError("");
    setLoading(true);

    contactApi
      .SendContactRequest(name, email, message)
      .then((x) => {
        if (x.success) {
          setSuccess("Your message has been sent successfully!");
          setName("");
          setEmail("");
          setMessage("");
        } else {
          setError(x.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("There was an unknown issue sending your message.");
        setLoading(false);
      });
  };

  return (
    <section className="illustration-section-01">
      <div className="container-sm section-inner">
        <h1 className="text-center">Contact Us</h1>
        <form className="flex-form" onSubmit={sendContactMessage}>
          <div className="form-item form-item-large">
            <label className="label">Name</label>
            <input className="input" onChange={(e) => setName(e.target.value)}></input>
          </div>
          <div className="form-item form-item-large">
            <label className="label">Email</label>
            <input className="input" onChange={(e) => setEmail(e.target.value)}></input>
          </div>
          <div className="form-item form-item-large">
            <label className="label">Message</label>
            <textarea className="input" style={{ width: "100%", height: "100%" }} rows="5" onChange={(e) => setMessage(e.target.value)}></textarea>
          </div>
          <div className="form-item form-item-large">
            <label className="label validation-label">{error}</label>
            <label className="label">{success}</label>
          </div>
          <div className="form-item form-item-large">
            <button style={{ width: "100%" }} className="button button-primary">
              <LoadingSpinner DisplayText="Send Message" isLoading={loading}></LoadingSpinner>
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
