/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { FaTrash, FaPen, FaCircle, FaChartLine } from "react-icons/fa";
import LoadingSpinner from '../elements/LoadingSpinner';

const WebsiteRow = ({website, editClicked, deleteWebsite, statsClicked}) => {

    let [isLoading, setIsLoading] = useState(false);

    let startEdit = () => {
        editClicked(website);
    }

    let startStats = () => {
        statsClicked(website);
    }

    let invokeDeleteWebsite = () => {
        setIsLoading(true);
        deleteWebsite(website).then(x => {
            setIsLoading(false);
        }).catch(x => {
            setIsLoading(false);
        });
    }

    let showStatus = () => {
        if(website.enabled){
            return (<FaCircle color="green"></FaCircle>)
        }else{
            return (<FaCircle color="red"></FaCircle>)
        }
    }


  return (
    <div className="dashboard-row">
        <div className="dashboard-line-1">
            <div className="dashboard-row-status">
                {showStatus()}
            </div>
            <div className="dashboard-row-website">
                {website.name == false ? website.url : website.name}
            </div>
        </div>
        <div className="dashboard-row-action">
            <button className="button button-primary" onClick={invokeDeleteWebsite}>
                <LoadingSpinner DisplayText={<FaTrash></FaTrash>} isLoading={isLoading}></LoadingSpinner>
            </button>
            <button className="button button-primary" onClick={startEdit}>
                <FaPen></FaPen>
            </button>
            <button className="button button-primary" onClick={startStats}>
                <FaChartLine></FaChartLine>
            </button>
        </div>
    </div>
  );
}

export default WebsiteRow;