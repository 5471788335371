import React from 'react'
import LayoutDefault from '../layouts/LayoutDefault';
import AppRoute from '../utils/AppRoute';
import CovidPost from './CovidPost/CovidPost';
import ImageStorage from './ImageStoragePost/ImageStorage';

const BlogRoutes = () => {

    return (
        <>
            <AppRoute exact path="/Blog/Tracking-Covid-Vaccine" component={CovidPost} layout={LayoutDefault} />
            <AppRoute exact path="/Blog/Where-To-Store-Images" component={ImageStorage} layout={LayoutDefault} />
        </>
    );
    

}

export default BlogRoutes;