class AccountApi {
    
    constructor() {
        if(process.env.NODE_ENV === "development"){
            this.baseUrl = "http://127.0.0.1:5000/api/";

        }else{
            this.baseUrl = "https://api.site-change.com/api/";
        }
    }

    GetAccountInfo() {
        return fetch(this.baseUrl + "Account/GetAccountInfo", {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(x => x.json());
    }

    GetLastSubscriptionDate() {
        return fetch(this.baseUrl + "Account/GetLastSubscriptionDate", {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(x => x.json());
    }
}

export default AccountApi;