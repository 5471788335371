import React from 'react'
import CovidImg from '../../assets/images/blog-image/covid.jpg'
import HomeImg from './HomePage.png'
import DashImg from './CovidTracker.png'

const CovidPost = () => {

    return (
        <section className="illustration-section-01">
            <div className="container section-inner text-center">
                <h2>Site Change Blog</h2>
                <div className="blog-post">
                    <img className="blog-header-image" src={CovidImg} alt="Covid Vaccine"/>
                    <div className="blog-area">
                        <div className="blog-header">
                            <h1>How to Get an Alert When A COVID-19 Vaccine becomes available in your area.</h1>
                        </div>
                        <p className="main">
                            Entering 2021, the COVID-19 vaccine is the thing on top of everyone's minds as the vaccine is slowly released to the general public. It can be a real hassle to keep an eye on the news and your local pharmacy's website to check for updates. 
                        </p>
                        <p className="main">
                            SiteChange is a modern website change detector that provides an easy solution to this problem and it only takes a few minutes to get email notifications setup so you can be the first to know when new updates about the vaccine are released. 
                        </p>
                        <p>
                            Start off by navigating to the <a href="https://site-change.com">SiteChange homepage</a> to create an account. If you already have an account then you can use the easy to use to create a new tracker. 
                        </p>
                        <img src={HomeImg} alt="Homepage"/>
                        <p>
                            Once you get to the homepage enter the website you would like to track and the email that you want the notifications sent to. We have compiled a list of websites that may ideal for you to track but we always recommend contacting your local pharmacy to make sure you will be receiving updates from the correct website.
                        </p>
                        <ul>
                            <li>
                                <a href="https://www.who.int/news-room/q-a-detail/coronavirus-disease-(covid-19)-vaccines">World Health Organization</a>
                            </li>
                            <li>
                                <a href="https://cvshealth.com/covid-19/vaccine-information">CVS Vaccine Information</a>
                            </li>
                            <li>
                                <a href="https://www.walgreens.com/topic/promotion/covid-vaccine.jsp">Walgreens Vaccine Information</a>
                            </li>
                        </ul>
                        <p>
                            Once you enter the details on our homepage a link will be sent to your email to finish setting up the tracker. Once you click the link the tracker will be started and you should shortly receive an email with a screenshot of the website. 
                        </p>
                        <p>
                        If at any time you need to make modifications to your tracker or create an additional tracker you can visit the SiteChange dashboard and click the pencil icon next to the website or the "Add New" button. SiteChange allows you to change the frequency that the websites are tracked and you can track 2 different websites daily free of charge. The emails we send are mobile friendly and show you the differences that have been detected on the page.  
                        </p>
                        <img src={DashImg} alt="Dashboard"/>
                        <p>
                        It's that simple to get started with website change detection using SiteChange. If you have any questions please do not hesitate to <a href="https://site-change.com/contactus">contact us</a> and we will be in touch with you in under 48 hours. 
                        </p>
                        <div className="text-center">
                            <a href="https://site-change.com" className="white text-center button button-primary">Get Started Tracking</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CovidPost;