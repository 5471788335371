/* eslint-disable default-case */
import React, { useState } from "react";
import PaymentApi from "../../api/PaymentApi";
import LoadingSpinner from "../elements/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer, FUNDING } from "@paypal/react-paypal-js";

const PaymentForm = ({ PlanName, Price, ClearPlan }) => {
  let publicKey = "";

  if (process.env.NODE_ENV === "development") {
    publicKey = "AUhyyUEnjzgoen75ezPlysfSBbmId0gm84sLREYNPIRIy1LTM6a66wqZgIGNtOa04F48Nn1qMMQ1wJ1i";
  } else {
    publicKey = "ARKrL7aLwC689dEGPEHLloVZPDN8CWiBMRmBf7lngmrj4Cdmb9sZbpxwrghasAxpI6ecCGKExczwMbQn";
  }

  return (
    <PayPalScriptProvider options={{ "client-id": publicKey, "vault": true, "intent": "subscription" }}>
      <div className="payment-holder">
        <PaymentFormInputs PlanName={PlanName} Price={Price} ClearPlan={ClearPlan}></PaymentFormInputs>
      </div>
    </PayPalScriptProvider>
  );
};

const PaymentFormInputs = ({ PlanName, Price, ClearPlan }) => {

  let subscriptions = {};

  if (process.env.NODE_ENV === "development") {
    subscriptions.basic = "P-26759430L12947245L7MSFGQ";
    subscriptions.premium = "P-5MV06256P7319881TL7MSF5Y";
    subscriptions.premiumPlus = "P-0EP609855K484474WL7MSGFY";
    subscriptions.enterprise = "P-7E005675E1844061UL7MSGTA";
    subscriptions.enterprisePlus = "P-6PU96792BX804183BL7MSG6A";
  } else {
    subscriptions.basic = "P-1YR39895428242455L7NJGTQ";
    subscriptions.premium = "P-1V953799A87096240L7NJG4Y";
    subscriptions.premiumPlus = "P-4LD76123DH3885731L7NJHDQ";
    subscriptions.enterprise = "P-0KJ67963X33155022L7NJHKQ";
    subscriptions.enterprisePlus = "P-6P9933151P809315SL7NJHSA";
  }

  const [{ isPending, isResolved }] = usePayPalScriptReducer();
  const history = useHistory();

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [error, setError] = useState([]);
  let paymentApi = new PaymentApi();

  const createSubscription = (data, actions) => {
    let planId = "";
    switch(PlanName){
      case "Basic":
        planId = subscriptions.basic;
        break;
      case "Premium":
        planId = subscriptions.premium;
        break;
      case "Premium+":
        planId = subscriptions.premiumPlus;
        break;
      case "Enterprise":
        planId = subscriptions.enterprise;
        break;
      case "Enterprise+":
        planId = subscriptions.enterprisePlus;
        break;
    }

    return actions.subscription.create({
      'intent': 'CAPTURE',
      'plan_id': planId,
      'application_context': {
        'shipping_preference': "NO_SHIPPING",
        'user_action': 'CONTINUE',
        'payment_method': {
          'payee_preferred': 'IMMEDIATE_PAYMENT_REQUIRED'
        }
      }
    });
  }

  const SubscriptionCreated = (data, actions) => {
    setLoadingPayment(true);
    return paymentApi.StartSubscription(data).then(x => {
      console.log(x);
      history.push("/dashboard");
      setLoadingPayment(false);
    });
  }

  const HideLoading = () => {
    setLoadingPayment(false);
  }

  const ShowForm = () => {
    if(isResolved && !loadingPayment)
    {
      return (
          <>  
            <PayPalButtons  onCancel={HideLoading} onError={HideLoading} onApprove={SubscriptionCreated} createSubscription={createSubscription} style={{'color': 'black'}}></PayPalButtons>
            <button className="button button-primary full-width bottom-margin" onClick={ClearPlan}>Back</button>

            <div className="medium-text full-width error bottom-padding">{error}</div>    
          </>
      );
    }
    else
    {
      return (
        <LoadingSpinner isLoading={true}></LoadingSpinner>
      )
    }
  }

  return ShowForm();
};

export default PaymentForm;
