import React, { useState } from "react";
import DashboardApi from "../../api/DashboardApi";
import LoadingSpinner from "../elements/LoadingSpinner";

const EditSite = ({ editingSite, setState }) => {

  let [name, setName] = useState(editingSite.name == null ? "" : editingSite.name);
  let [url, setUrl] = useState(editingSite.url);
  let [active, setActive] = useState(editingSite.enabled);
  let [frequency, setFrequency] = useState(editingSite.checkMode);
  let [mode, setMode] = useState(editingSite.diffMode);
  let [error, setError] = useState("");
  let [isLoading, setIsLoading] = useState(false);

  let dashboardApi = new DashboardApi();

  let saveWebsite = () => {
    if(!url){
      setError("A website is required.");
      return;
    }else{
      setError("");
    }
    setIsLoading(true);
    dashboardApi.SaveWebsite({
      id: editingSite.id,
      enabled: active === "false" ? false : true,
      name: name,
      url: url,
      diffMode: parseInt(mode),
      checkMode: parseInt(frequency)
    }).then(x => {
      if(x.success){
        setState("current")
      }else{
        setError(x.message);
      }
      setIsLoading(false);
    }).catch(x => {
      setError("An unknown error as occurred.");
      setIsLoading(false);
    });
  }

  let showValidationError = () => {
    if(error){
      return <label className="validation-label">{error}</label>
    }else{
      return <></>
    }
  }

  return (
    <div className="dashboard-pane">
      <h3 className="animate__animated animate__fadeInDown animate__faster">Edit Site</h3>
      <div className="dashboard-area dashboard-with-bottom">
        <div className="dashboard-form animate__animated animate__fadeInUp animate__faster">
          <div className="form-item form-item-large">
            <label className="label">Tracker Name</label>
            <input className="input" value={name} onChange={(e) => setName(e.target.value)}></input>
          </div>
          <div className="form-item  form-item-large">
            <label className="label">Website</label>
            <input className="input" disabled="disabled" value={url} onChange={(e) => setUrl(e.target.value)}></input>
          </div>
          <div className="form-item">
            <label className="label">Active</label>
            <select className="input" value={active.toString().toLowerCase()} onChange={(e) => setActive(e.target.value)}>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <div className="form-item">
            <label className="label">Frequency</label>
            <select className="input" value={frequency} onChange={(e) => setFrequency(e.target.value)}>
              <option value="0">5 Minutes (9000 Checks)</option>
              <option value="1">30 Minutes (1500 Checks)</option>
              <option value="2">Hourly (750 Checks)</option>
              <option value="3">12 Hours (62 Checks)</option>
              <option value="4">Daily (31 Checks)</option>
            </select>
          </div>
        </div>
        <div className="footer-button-group animate__animated animate__fadeInUp animate__faster">
          {showValidationError()}
          <button className="button button-primary" onClick={() => setState("current")}>Back</button>
          <button className="button button-primary" onClick={saveWebsite}>
            <LoadingSpinner DisplayText="Save" isLoading={isLoading}></LoadingSpinner>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSite;
