class AuthApi {
    
    constructor() {
        if(process.env.NODE_ENV === "development"){
            this.baseUrl = "http://127.0.0.1:5000/api/";

        }else{
            this.baseUrl = "https://api.site-change.com/api/";
        }
    }

    Register(website, email) {
        return fetch(this.baseUrl + "Auth/Register", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                website: website,
                affiliate: localStorage.getItem("affiliate")
            })
        }).then(x => x.json());
    }

    RegisterLifeTime(website, email, key) {
        return fetch(this.baseUrl + "Auth/RegisterLifetime", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                website: website,
                affiliate: localStorage.getItem("affiliate"),
                key: key
            })
        }).then(x => x.json());
    }

    ForgotPassword(email) {
        return fetch(this.baseUrl + "Auth/ForgotPassword", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        }).then(x => x.json());
    }

    ChangePassword(email, password, newPassword) {
        return fetch(this.baseUrl + "Auth/ChangePassword", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password,
                newPassword: newPassword
            })
        }).then(x => x.json());
    }

    Login(email, password) {
        return fetch(this.baseUrl + "Auth/Login", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        }).then(x => x.json());
    }

    IsLoggedIn(){
        return fetch(this.baseUrl + "Auth/IsLoggedIn", {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        });
    }
}

export default AuthApi;