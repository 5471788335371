import React from 'react';

const LayoutNoNav = ({ children }) => (
  <>
    <main className="site-content">
      {children}
    </main>
  </>
);

export default LayoutNoNav;  