/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthApi from '../api/AuthApi';
import Logo from '../components/layout/partials/Logo';
import LoadingSpinner from "../components/elements/LoadingSpinner"
import { Link } from 'react-router-dom';

const Login = () => {

  let authApi = new AuthApi();

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [error, setError] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [pageLoad, setPageLoad] = useState(false);

  let updateEmail = (e) => setEmail(e.target.value);
  let updatePassword = (e) => setPassword(e.target.value);
  
  const history = useHistory();

  useEffect(() => {
    authApi.IsLoggedIn().then(x => {
      if(x.ok){
        history.push("/Dashboard");
      }else{
        setPageLoad(true);
      }
    });
  }, [])

  let userLogin = (e) => {
    e.preventDefault();

    if(!email || !password){
      setError("Please enter a username and password");
      return;
    }
    else
    {
      setError("");
    }

    setIsLoading(true);
    authApi.Login(email, password).then(result => {
      if(result.success){
        localStorage.setItem("token", result.results.token);
        history.push("/Dashboard");
      }else{
        setError(result.message);
      }
      setIsLoading(false);
    }).catch(x => {
      setError("An unknown error occurred");
      setIsLoading(false);
    });
  }

  let showValidationError = () => {
    if(error){
      return <label className="validation-label">{error}</label>
    }else{
      return <></>
    }
  }

  if(pageLoad){
    return (
      <div>
          <div className="container">
            <div className="site-header-inner">
              <Logo></Logo>
            </div>
            <h1 className="login-header animate__animated animate__fadeInDown animate__faster">Login</h1>
            <div className="login-wrapper animate__animated animate__fadeInUp animate__faster">
              <form className="login-box" onSubmit={(e) => userLogin(e)}>
                <label className="login-label">Email</label>
                <input className="input login-input" value={email} onChange={updateEmail}></input>
                <label className="login-label">Password</label>
                <input type="password" className="input login-input" value={password} onChange={updatePassword}></input>
                {showValidationError()}
                <button type="submit" className="button button-primary login-button">
                  <LoadingSpinner isLoading={isLoading} DisplayText="Login"></LoadingSpinner>
                </button>
                <Link style={
                  {
                    width: '100%', 
                    textAlign: 'center', 
                    textDecoration: 'underline',
                    fontSize: 'medium',
                    cursor: 'pointer'
                  }} to="/ForgotPassword">
                    Forgot Password?
                </Link>
                <span style={
                  {
                    width: '100%', 
                    textAlign: 'center', 
                    fontSize: 'medium'
                  }}>
                  By using this website you agree to the <Link to="/PrivacyPolicy">privacy policy</Link> and the <Link to="/TermsConditions">terms and conditions</Link>
                </span>
              </form>
            </div>
          </div>
      </div>
    );
  }else{
    return (
      <div style={{textAlign: "center", marginTop: "200px"}}>
        <LoadingSpinner isLoading={true}></LoadingSpinner>
      </div>
    );
  }
  
}

export default Login;