/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthApi from '../api/AuthApi';
import Logo from '../components/layout/partials/Logo';
import LoadingSpinner from "../components/elements/LoadingSpinner"
import { Link } from 'react-router-dom';

const ForgotPassword = () => {

  let authApi = new AuthApi();

  let [email, setEmail] = useState("");
  let [error, setError] = useState("");
  let [message, setMessage] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [pageLoad, setPageLoad] = useState(false);

  let updateEmail = (e) => setEmail(e.target.value);
  
  const history = useHistory();

  useEffect(() => {
    authApi.IsLoggedIn().then(x => {
      if(x.ok){
        history.push("/Dashboard");
      }else{
        setPageLoad(true);
      }
    });
  }, [])

  let requestReset = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("")
    setMessage("");
    authApi.ForgotPassword(email).then(x => {
      setMessage(x.message);
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
      setError("An unknown issue has occurred. Please try again or contact support at support@site-change.com.")
    })
  }

  let showValidationError = () => {
    if(message){
      return <label>{message}</label>
    }else{
      return <></>
    }
  }

  let showSuccessMessage = () => {
    if(error){
      return <label className="validation-label">{error}</label>
    }else{
      return <></>
    }
  }

  if(pageLoad){
    return (
      <div>
          <div className="container">
            <div className="site-header-inner">
              <Logo></Logo>
            </div>
            <h1 className="login-header animate__animated animate__fadeInDown animate__faster">Forgot Password</h1>
            <div className="login-wrapper animate__animated animate__fadeInUp animate__faster">
              <form className="login-box" onSubmit={(e) => requestReset(e)}>
                <label className="login-label">Email</label>
                <input className="input login-input" value={email} onChange={updateEmail}></input>
                {showValidationError()}
                {showSuccessMessage()}
                <button type="submit" className="button button-primary login-button">
                  <LoadingSpinner isLoading={isLoading} DisplayText="Reset Password"></LoadingSpinner>
                </button>
                <Link style={
                  {
                    width: '100%', 
                    textAlign: 'center', 
                    textDecoration: 'underline',
                    fontSize: 'medium',
                    cursor: 'pointer'
                  }} to="/Login">
                    Back to Login
                </Link>
              </form>
            </div>
          </div>
      </div>
    );
  }else{
    return (
      <div style={{textAlign: "center", marginTop: "200px"}}>
        <LoadingSpinner isLoading={true}></LoadingSpinner>
      </div>
    );
  }
  
}

export default ForgotPassword;