import React from "react";
import HeaderDash from "../components/layout/HeaderDash";

const LayoutDash = ({ children, displayMode, setDisplayMode }) => {

  return (
    <>
      <HeaderDash displayState={displayMode} setDisplayState={setDisplayMode} navPosition="right" />
      <main className="site-content">{children}</main>
    </>
  );
};

export default LayoutDash;
