class DashboardApi {
    
    constructor() {
        if(process.env.NODE_ENV === "development"){
            this.baseUrl = "http://127.0.0.1:5000/api/";

        }else{
            this.baseUrl = "https://api.site-change.com/api/";
        }
    }

    GetChecksRemaining(){
        return fetch(this.baseUrl + "Dashboard/GetChecksRemaining", {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(x => x.json());
    }

    GetTrackedSites(){
        return fetch(this.baseUrl + "Dashboard/GetTrackedSites", {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(x => x.json());
    }

    GetStatsData(websiteId){
        return fetch(this.baseUrl + `Dashboard/GetStatsData?websiteId=${websiteId}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(x => x.json());
    }

    SaveWebsite(website) {
        return fetch(this.baseUrl + "Dashboard/EditTrackedSite", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify(website)
        }).then(x => x.json());
    }

    CreateWebsite(website) {
        return fetch(this.baseUrl + "Dashboard/CreateTrackedSite", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify(website)
        }).then(x => x.json());
    }

    DeleteWebsite(website) {
        console.log(website.id);
        return fetch(this.baseUrl + "Dashboard/DeleteTrackedSite", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify({
                id: website.id
            })
        }).then(x => x.json());
    }
}

export default DashboardApi;