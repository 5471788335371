import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import Button from "../elements/Button";

const PriceCard = ({
    PlanName,
    Price,
    Length,
    PageAmount,
    PageFrequency,
    Features,
    CheckAmount,
    CardNumber,
    IsCurrent,
    Hidden,
    setSelectedItem,
    SelectedItem,
    NoButton,
    ButtonText,
    ButtonLink
}) => {

    const history = useHistory();

    let GetButtonText = () => {

        if(ButtonText){
            return ButtonText;
        }
        
        if (SelectedItem && SelectedItem.PlanName === PlanName){
            return (
                "New Plan"
            )
        }else{
            return (
                IsCurrent ? "Current" : "Select"
            )
        }
    }

    if(Hidden){
        return (<></>)
    }

    let selectCurrentItem = () => {
        if(ButtonLink){
            if(ButtonLink.startsWith("#")){
                document.querySelector(ButtonLink).scrollIntoView({behavior: "smooth"});
            }else{
                history.push(ButtonLink);
            }
        }else{
            if(!IsCurrent){
                setSelectedItem({
                    PlanName: PlanName,
                    Price: Price
                })
            }
        }
    }

    const GetButton = () => {
        if(NoButton){
            return (<></>)
        }else{
            return (
                <Button className={"button button-primary full-width " + (IsCurrent ? "button-current" : "")} onClick={selectCurrentItem}>
                    {GetButtonText()}
                </Button>
            )
        }
    }

    return (
            <div className="upgrade-card">
                <div className={"upgrade-header card-" + CardNumber}>
                    <div className="plan-name">
                        {PlanName}
                    </div>
                    <div className="plan-price">
                        {Price === "Free" ? Price : "$" + Price}
                    </div>
                    <div className="small-description">
                        {Length}
                    </div>
                </div>
                <div className="upgrade-body">
                    <div className="page-count">
                        {PageAmount}
                    </div>
                    <div className="small-description small-padding">
                        {PageFrequency}
                    </div>
                    <div className="feature-list">
                        <ul className="clean-list">
                            {Features.map(x => {
                                return (
                                    <li key={x}>
                                        {x}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    {GetButton()}
                    <div className="smallest-description small-padding">
                        {CheckAmount} Checks/Month
                    </div>
                </div>
            </div>
    );

}

export default PriceCard;