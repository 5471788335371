/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardApi from "../../api/DashboardApi";
import LoadingSpinner from "../elements/LoadingSpinner";
import { Line } from 'react-chartjs-2'

const StatSite = ({ editingSite, setState }) => {

  let [error, setError] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [chartData, setChartData] = useState({});

  let dashboardApi = new DashboardApi();

  useEffect(() => {
    exportStats();
  }, [])

  const exportStats = () => {
    setIsLoading(true);
    dashboardApi.GetStatsData(editingSite.id).then(x => {
      if(x.success){
        setChartData(x.results);
      }
      else
      {
        setError("There was an error loading the stats for this website.");
      }
      setIsLoading(false);
    }).catch(err => {
      setError("There was an unknown error loading the stats for this website.");
      setIsLoading(false);
    })
  }

  let showValidationError = () => {
    if(error){
      return <label className="validation-label">{error}</label>
    }else{
      return <></>
    }
  }

  return (
    <div className="dashboard-pane">
      <h3 className="animate__animated animate__fadeInDown animate__faster">Site Statistics</h3>
      <div className="dashboard-area dashboard-with-bottom">
        <div className="dashboard-form animate__animated animate__fadeInUp animate__faster">
          <div className="form-item form-item-large text-center bottom-padding">
            <label className="label">Changes over the past month</label>
          </div>
          <div style={{width: '100%', height: '450px'}}>
            <Line data={chartData} options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false
              },
              scales: {
                yAxes: [{
                  ticks: {
                    min: 0,
                    stepSize: 1,
                    fontColor: '#9CA9B3'
                  }
                }],
                xAxes: [{
                  ticks: {
                    fontColor: '#9CA9B3'
                  }
                }]
              }
            }}></Line>
          </div>
        </div>
        <div className="footer-button-group animate__animated animate__fadeInUp animate__faster">
          {showValidationError()}
          <button className="button button-primary" onClick={() => setState("current")}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default StatSite;
