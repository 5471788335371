import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import AuthApi from "../../api/AuthApi";
import LoadingSpinner from "../elements/LoadingSpinner"

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({ className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, ...props }) => {

  const outerClasses = classNames("hero section center-content", topOuterDivider && "has-top-divider", bottomOuterDivider && "has-bottom-divider", hasBgColor && "has-bg-color", invertColor && "invert-color", className);

  const innerClasses = classNames("hero-inner section-inner", topDivider && "has-top-divider", bottomDivider && "has-bottom-divider");

  let authApi = new AuthApi();

  var [isLoading, setIsLoading] = useState(false);

  var [email, setEmail] = useState("");
  let onEmailChange = e => setEmail(e.target.value);

  var [website, setWebsite] = useState("");
  let onWebsiteChange = e => setWebsite(e.target.value);

  var [errorMessage, setErrorMessage] = useState("");
  let getErrorMessage = () => {
    if(errorMessage === ""){
      return (<></>)
    }
    else
    {
      return (
        <div style={{color: "red", marginTop: "-15px", fontSize: "17px"}}>
          {errorMessage}
        </div>
      )
    }
  }

  const history = useHistory();

  let onStartTrackingClick = e => {
    if(!website || !email){
      setErrorMessage("Please enter a website and email.");
      return;
    }else{
      setErrorMessage("");
    }
    setIsLoading(true);
    authApi.Register(website, email).then(result => {
      console.log(result);
      if(result.success){
        history.push("/AlmostThere");
      }else{
        setErrorMessage(result.message);
      }
      setIsLoading(false);
    }).catch(x => {
      setErrorMessage("An unknown error has occurred.");
      setIsLoading(false);
    });
  };

  return (
    <section {...props} className={outerClasses} id="Signup">
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">Site Change</span> is the easiest way to track changes on any website
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Our tool provides an easy free solution that allows you to never miss out on a website change.
              </p>
            </div>
          </div>
          <div className="reveal-from-bottom mb-32" data-reveal-delay="600">
            <div className="mb-16">
              <label className="inputLabel">Website</label><br/>
              <input className="input" type="text" value={website} onChange={onWebsiteChange}></input>
            </div>
            <div className="mb-16">
              <label className="inputLabel">Email Address</label><br/>
              <input className="input" type="text" value={email} onChange={onEmailChange}></input>
            </div>
          </div>
          {getErrorMessage()}
          <div className="reveal-from-bottom instant-reveal" data-reveal-delay="600">
            <ButtonGroup>
              <Button color="primary" style={{width: "286px"}} wideMobile onClick={onStartTrackingClick}>
                <LoadingSpinner isLoading={isLoading} DisplayText="Start Tracking"></LoadingSpinner>
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
