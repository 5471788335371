/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import AccountApi from "../../api/AccountApi";
import LoadingSpinner from "../elements/LoadingSpinner";
import { Link } from "react-router-dom"
const AccountInfo = () => {

  let [isLoading, setIsLoading] = useState(false);
  const accountApi = new AccountApi();

  var [email, setEmail] = useState(null);
  var [checks, setChecks] = useState(null);
  var [subPlan, setSubPlan] = useState(null);
  var [renewDate, setRenewDate] = useState(null);
  var [isBilling, setIsBilling] = useState(false);
  var [last4, setLast4] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    accountApi.GetAccountInfo().then(x => {
      setEmail(x.email);
      setChecks(x.totalChecks);
      setSubPlan(x.plan);
      if(x.nextBill !== null){
        setRenewDate(new Date(x.nextBill));
      }
      setIsBilling(x.isBilling);
      setLast4(x.last4);
      setIsLoading(false);
    }).catch(x => {
      console.log(x);
    })
  }, [])

  const GetBillingDateText = () => {
    if(isBilling){
      return renewDate.toLocaleDateString();
    }else{
      if(renewDate !== null){
        return "None, subscription ends " + renewDate.toLocaleDateString();
      }else{
        return "None"
      }
    }
  }

  const GetChangeCardButton = () => {
    if(last4 !== null){
      return (
        <Link to="/Upgrade" className="button button-primary">
          Change Card
        </Link>
      )
    }
  }

  const AccountInformation = () => {
    if(isLoading){
      return (
        <div className="dashboard-form top-padding">
          <LoadingSpinner isLoading={isLoading}></LoadingSpinner>
        </div>
      )
    }

    return (
      <>
        <div className="dashboard-form">
          <div className="form-item">
            <label className="label bold">Email Address</label>
            <label className="label">{email}&nbsp;</label>
          </div>
          <div className="form-item">
            <label className="label bold">Monthly Checks</label>
            <label className="label">{checks}&nbsp;</label>
          </div>
          <div className="form-item">
            <label className="label bold">Current Plan</label>
            <label className="label">{subPlan !== null ? subPlan : "Starter"}&nbsp;</label>
          </div>
          <div className="form-item">
            <label className="label bold">Next Billing Date</label>
            <label className="label">{GetBillingDateText()}&nbsp;</label>
          </div>
        </div>
        <div className="footer-button-group">
          <Link to="/ContactUs" className="button button-primary">
            Contact Support
          </Link>
          <Link to="/Upgrade" className="button button-primary">
            Change Subscription
          </Link>
          {GetChangeCardButton()}
        </div>
      </>
    )
  }

  return (
    <div className="dashboard-pane">
      <h3 className="animate__animated animate__fadeInDown animate__faster">Account Information</h3>
      <div className="dashboard-area dashboard-with-bottom">
        {AccountInformation()}
      </div>
    </div>
  );
};

export default AccountInfo;
