import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({ className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, pushLeft, ...props }) => {
  const outerClasses = classNames("features-tiles section", topOuterDivider && "has-top-divider", bottomOuterDivider && "has-bottom-divider", hasBgColor && "has-bg-color", invertColor && "invert-color", className);

  const innerClasses = classNames("features-tiles-inner section-inner pt-0", topDivider && "has-top-divider", bottomDivider && "has-bottom-divider");

  const tilesClasses = classNames("tiles-wrap center-content", pushLeft && "push-left");

  const sectionHeader = {
    title: "Never miss a change",
    paragraph: "Our custom solution is optimized for speed and reliability meaning that you never miss a website change.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={require("./../../assets/images/feature-tile-icon-01.svg")} alt="Features tile icon 01" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Simple</h4>
                  <p className="m-0 text-sm">No need to hassle with tons of settings and options. Let our smart detection algorithms to the heavy lifting. You just need a website and an email. </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={require("./../../assets/images/feature-tile-icon-02.svg")} alt="Features tile icon 02" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Email</h4>
                  <p className="m-0 text-sm">Instant email notifications are sent as soon as a change is detected. Our custom diff tool will send you an image of the changes. With a link to visit your website right </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={require("./../../assets/images/feature-tile-icon-03.svg")} alt="Features tile icon 03" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Visual Tracking</h4>
                  <p className="m-0 text-sm">Don't need to track changes you can't see? Our visual detector will take a snapshot of the web page and if anything changes you will get alerted instantly.</p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={require("./../../assets/images/feature-tile-icon-04.svg")} alt="Features tile icon 04" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Free</h4>
                  <p className="m-0 text-sm">Our free plan allows you to track 2 websites completely free of charge for as long as you want.</p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={require("./../../assets/images/feature-tile-icon-05.svg")} alt="Features tile icon 05" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Powerful</h4>
                  <p className="m-0 text-sm">Built with the same service that powers Amazon you can be guaranteed 99.9% uptime and amazing reliability.</p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image src={require("./../../assets/images/feature-tile-icon-06.svg")} alt="Features tile icon 06" width={64} height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Dashboard</h4>
                  <p className="m-0 text-sm">Site Change has a modern dashboard allows you to stay on top of websites you are tracking by letting you know when the changes happen.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
